import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Introduction</h1>
      <p>
        At Mido PLC (referred to as "Mido", "we", "us", and "our"), we are
        committed to protecting the privacy of our users. If you are in
        Ethiopia, we are the data controller, unless otherwise stated. Please
        read the following privacy policy that explains how we collect, manage,
        and protect your personal information.
      </p>
      <p>
        This is the Privacy (and Cookies) Notice ("Notice") for the website
        www.midobooking.com, and all Mido affiliate sites ("Sites"), or our
        applications ("Apps") (together "Services").
      </p>

      <h2>Contacting us</h2>
      <p>
        If you have any questions, comments, and requests regarding this Notice,
        you can get in touch with us via our Contact Us page, or via email at
        support@midobooking.com.
      </p>

      <h2>What information do we collect & how do we use it?</h2>
      <p>This Privacy Notice will apply to you if:</p>
      <ul>
        <li>you visit and browse our Sites and Apps.</li>
        <li>
          You order products from or book appointments with our Partners through
          us (we'll refer to you as a "Client").
        </li>
        <li>
          you are self-employed and/or you work for a business on the Mido
          platform (we'll refer to you and/or the business as a "Partner").
        </li>
      </ul>
      <p>
        We will only use the information we collect about you if there is a
        reason for doing so, and if that reason is permitted under data
        protection law.
      </p>

      <h3>If you visit our services</h3>
      <p>
        We use your information to provide you with our Services. If you visit
        any of our Services, whether you're just browsing or you have an
        account, we will automatically collect information from you each time
        you use our Sites. This includes technical information, information
        about your visit and (if you opt-in) location data:
      </p>

      <h4>Technical information</h4>
      <p>
        Technical information may include the phone number, Internet Protocol
        (IP) address, login information, browser type and version, browser
        plug-in types and versions, device IDs, social log-in ID/email address,
        time zone setting, operating system and platform, hardware version,
        device settings (e.g., language and time zone), file & software names
        and types (associated with your device and/or the Services), battery &
        signal strength, information relating to your mobile operator or
        Internet Service Provider (ISP).
      </p>

      <h4>Information about your visit</h4>
      <p>
        Information about your visit may include the full Uniform Resource
        Locators (URL), clickstream to, through and from our Site (including
        date and time), pages and services you viewed or searched for,
        demographic information (including age and gender), page response times,
        download errors, length of visits to certain pages, page interaction
        information (such as scrolling, clicks, and mouse-overs), traceable
        campaign links (e.g., in emails, or via tracking URLs), methods used to
        browse away from the page, and any phone number used to call our
        customer service number or social media handle used to connect with our
        customer service team and our social media accounts.
      </p>

      <h4>Location data</h4>
      <p>
        Location data includes country location (based on your full or partial
        IP address and/or Google Analytics information) which we use to provide
        location services (if you ask or permit us to), so that we can deliver
        content, advertising or other services that are dependent on knowing
        where you are, like checking for fraudulent transactions.
      </p>
      <p>
        Location data may be collected in combination with device ID, so we can
        recognise your mobile browser or device when you return to the Service.
      </p>
      <p>
        Delivery of location services will involve us checking any of the
        following:
      </p>
      <ul>
        <li>the coordinates (latitude/longitude) of your location,</li>
        <li>
          your current country or region, by referencing your current IP address
          against public sources, and/or
        </li>
        <li>
          your Identifier for Advertisers (IFA) code for your Apple device, or
          the Android ID for your Android device, or a similar device
          identifier.
        </li>
      </ul>
      <p>
        You can opt-in and out of location sharing by changing your device
        settings
      </p>

      <p>We use this automatically collected information to:</p>
      <ul>
        <li>
          understand how individuals use our Site, and how we can improve it;
        </li>
        <li>
          ensure content from our site is presented in the most effective manner
          for you and for your devices; and
        </li>
        <li>
          provide you with the information, products, and services that you
          request from us or we think you may be interested in.
        </li>
      </ul>

      <h2>Contact or Engagement Information</h2>
      <p>
        If you contact or engage with us, we will collect your contact
        information and other communications information you provide.
      </p>
      <p>Contact information includes basic contact information you provide:</p>
      <ul>
        <li>Email address</li>
        <li>First and last name</li>
        <li>Phone number(s)</li>
        <li>
          Social media handle (e.g., if you engage with us on social media)
        </li>
      </ul>
      <p>
        Communications information includes your correspondence with us, for
        example if you get in touch with our Customer Service team about an
        order or booking or to report a problem with our Site. This includes:
      </p>
      <ul>
        <li>Emails</li>
        <li>Texts, in-app messaging & other digital messaging</li>
        <li>Calls</li>
        <li>Letters</li>
        <li>Any in-person conversations you have with us</li>
      </ul>
      <p>We use this contact and communications information to:</p>
      <ul>
        <li>
          Contact you if you have asked us to do so, including to respond to
          your queries, troubleshoot problems, and help with any issues you may
          have with our Services
        </li>
        <li>
          Provide you with information you might request about our Services
        </li>
        <li>
          Put you in touch with Partners you ask us to share your information
          with
        </li>
      </ul>

      <h2>Appointment Booking Information</h2>
      <p>
        When you use our services to book an appointment, we may collect the
        following information:
      </p>
      <ul>
        <li>First and last name</li>
        <li>Date of birth</li>
        <li>Gender</li>
        <li>Email address</li>
        <li>Address</li>
        <li>Mobile phone number(s)</li>
        <li>Your photo, if uploaded (optional)</li>
      </ul>
      <p>
        If you choose to use your Google, Facebook, or Apple account to sign
        into Mido, we will get an access token that enables this sign-in process
        (granting us access to your first and last name, email, user ID and
        (apart from Apple) your profile photo). We assign unique user IDs or
        other account-level IDs to your Mido account.
      </p>
      <p>As you use your account, we may collect information about your:</p>
      <ul>
        <li>
          Purchase history, including product orders or appointment bookings, or
          voucher purchase and use
        </li>
        <li>Favorites (e.g., your favorite salon or gym)</li>
        <li>Account settings</li>
        <li>Social login information</li>
        <li>Securely saved payment methods</li>
        <li>Preferences, referrals, or how you found out about our Services</li>
        <li>Other user-generated content (e.g., profile photo, reviews)</li>
      </ul>
      <p>
        We use this account and related information to manage your account with
        us, including to:
      </p>
      <ul>
        <li>Maintain your Mido account</li>
        <li>Provide customer support</li>
        <li>
          Enable you to contact, order from, and make bookings with our Partners
        </li>
        <li>
          Enable you to make and pay for orders and bookings with your account
        </li>
        <li>
          If a Partner offers home services and you select the option, to enable
          you to book appointments at the address you provide
        </li>
        <li>
          Provide you with a personalized experience, including recommending
          features, services, products, and Partners we think you may be
          interested in
        </li>
      </ul>

      <h2>Ordering from a Partner's Shop</h2>
      <p>
        When you order any products from a Partner's shop, we may collect the
        following information:
      </p>
      <ul>
        <li>
          Order details and purchase records, including any related
          correspondence via our Services
        </li>
        <li>Cancellation, return, and refund records</li>
        <li>Securely saved payment methods</li>
        <li>Your product reviews or ratings</li>
      </ul>
      <p>
        We use your account and order information to enable our Partners to
        fulfill and manage your orders, including to:
      </p>
      <ul>
        <li>
          Enable you to contact and purchase products from our Partners,
          including making payments
        </li>
        <li>
          Share your contact and order information with the Partner you ordered
          from, so the Partner can fulfill and manage your order (including
          processing any cancellations, returns, or refunds), and communicate
          with you about your order
        </li>
        <li>Store your order, cancellation, return, and refund records</li>
      </ul>
      <h2>If you work for a Partner</h2>
      <p>
        If you work for our Partner (e.g. as an employee, contractor, or
        worker), we may collect contact and company information from you:
      </p>
      <p>Contact information may include:</p>
      <ul>
        <li>First and last name</li>
        <li>Role title</li>
        <li>Company name</li>
        <li>Email address</li>
        <li>Contact number</li>
        <li>Log-in details</li>
      </ul>
      <p>Company information may include:</p>
      <ul>
        <li>Company address</li>
        <li>Business type (e.g. salon, spa, gym)</li>
        <li>Consultation form content</li>
        <li>Historical and future appointments</li>
        <li>Point of sale data</li>
        <li>
          Payment processing data (we don't see this, only our payment
          processors do)
        </li>
        <li>Reporting and analytics</li>
        <li>Client data and promotions</li>
        <li>Pictures and marketing materials</li>
      </ul>
      <p>
        We also process other information on behalf of your company that is not
        personal data (for example, financial data and product inventory).
      </p>
      <p>
        We use this contact and company information to manage the Partner's
        account with us, including to:
      </p>
      <ul>
        <li>Maintain the Partner's account</li>
        <li>
          Provide Partner customer support, including technical support for our
          suite of business tools
        </li>
        <li>
          Enable the Partner to use our suite of business tools, including
          managing staff and appointment scheduling
        </li>
        <li>Enable the Partner to make transactions and manage payments</li>
        <li>
          Enable the Partner to manage marketing promotions via the Services
        </li>
        <li>Enable the Partner to list on the Mido's marketplace</li>
        <li>
          Enable the Partner to manage inventory and use point of sale devices
        </li>
        <li>
          Contact Partner staff to enable them to create a Partner-specific
          account with appropriate authorizations, and to manage those accounts
        </li>
      </ul>

      <h2>
        If we send you offers, updates or other marketing material from us
      </h2>
      <p>
        We may collect contact information (as explained above), like your name
        and email address.
      </p>
      <p>
        We may also collect marketing preferences, which are our records of what
        information you would or would not like to receive from us, and if you
        have opted out of any direct marketing.
      </p>
      <p>We use this contact and marketing preference information to:</p>
      <ul>
        <li>
          Send you offers, updates, promotions, newsletter(s), insights, and
          other marketing material
        </li>
        <li>
          Send you personalized marketing, including offers, features, services,
          products, and Partners we think you may be interested in
        </li>
        <li>
          Contact you if you work for a company we are interested in partnering
          with, about our Services and opportunities with Mido
        </li>
        <li>
          Contact you to ask for feedback, including through surveys and other
          marketing research
        </li>
      </ul>
      <p>
        You can opt-out of further marketing at any time by selecting the
        “unsubscribe” link at the end of all our promotional updates.
      </p>

      <h2>What do each of these legal reasons mean?</h2>
      <p>
        We must have a relevant legal justification, called a 'lawful basis' for
        each way in which we use your personal information.
      </p>
      <p>
        Lawful bases may include consent, a contract with you (as a data
        subject), compliance with our legal obligations and our specified
        legitimate interests.
      </p>
      <p>
        Consent: We'll use your personal information to send you promotional or
        marketing content (for example, our insight updates or newsletters), if
        you have given us consent to us doing so, where required by law. We may
        also send direct marketing based on our legitimate interests.
      </p>
      <p>
        You can opt-out of further marketing at any time by selecting the
        “unsubscribe” link at the end of all our promotional updates and
        marketing to you.
      </p>
      <p>
        We also rely on consent for some of the cookies we use (see our Cookies
        Notice for more detail).
      </p>
      <p>
        Contract: We collect, store and process your personal information if it
        is necessary for performing a contract you have with us (for example,
        our Terms of Use and Terms of Service), or where you have asked us to
        take specific steps before entering into that contract. This does not
        include any agreement we have with a Partner, whether you are a Booker
        or Partner staff – this is based on our legitimate interests.
      </p>
      <p>
        Legal obligation: We may need to process any of your personal
        information to comply with our legal obligations, including under
        applicable Ethiopia law, and/or any court orders. This may include
        compliance with know-your-client and anti-money laundering rules.
      </p>
      <p>
        Legitimate interests: We may process your personal information if it is
        necessary for our legitimate interests or the legitimate interests of a
        third party, provided those interests are not outweighed by your rights
        and interests.
      </p>
      <p>Our legitimate interests include:</p>
      <ul>
        <li>Administering, improving and expanding our Site and services</li>
        <li>Keeping our records updated</li>
        <li>Gaining insights on how you use our Services</li>
        <li>Gaining your feedback and reviews</li>
        <li>Delivering, developing and improving our Services</li>
        <li>
          Enhancing, customizing or modifying our Services and communications
        </li>
        <li>Implementing and improving our security measures</li>
        <li>Growing our business and informing our marketing strategy</li>
        <li>
          Marketing and promoting our Services to a company you work for or
          provide services to
        </li>
        <li>
          Measuring or understanding the effectiveness of advertising we serve
          to you and others and delivering relevant advertising to you
          (including when you visit other websites)
        </li>
        <li>Fulfilling agreements with another organization</li>
        <li>
          Complying with or enforcing any agreement Mido has (or that you may
          have, for example any extra Partner-specific terms) with a Partner you
          have purchased goods or services from as a Client
        </li>
        <li>
          Complying with or enforcing any agreement we may have with a company
          you work for or provide services to (for example, if you work at a
          Partner)
        </li>
      </ul>
      <p>
        In each case, these legitimate interests are only valid if they are not
        outweighed by your rights and interests. If you would like further
        information about how we assess our legitimate interests, please contact
        us at support@mido.com.
      </p>
      <p>
        We also use different types of cookies on our Site – we explain this in
        the Cookies Notice.
      </p>
      <h2>Who do we share your information with?</h2>
      <p>
        <strong>If you are a Client:</strong>
      </p>
      <p>
        We will share your information with our Partners if you have asked us to
        (for example, if you want to connect with, order from or make a booking
        with one of our Partners). We may share your contact and account
        information, and they will receive any other information you provide
        through Mido (for example, completing a Partner's consultation form).
        Partners may use this information to manage your communications, orders
        and bookings with them.
      </p>
      <p>
        If you leave a public review for a Partner (or any of their products in
        their shop on our Services), your rating, review, review date, and first
        name and last initial will be published on Mido. Other Mido users and
        members of the public may be able to see your reviews. Reviews may be
        published or syndicated on third-party websites. Public reviews will
        survive the termination or expiry of the Mido Terms of Service.
      </p>

      <p>
        <strong>If you work for a Partner:</strong>
      </p>
      <p>
        Mido users may rate the Partner you work at or for, and may rate you as
        an individual. Your individual ratings may be shared with the relevant
        Partner, and may be publicly available on our Services.
      </p>
      <p>
        We may publish limited Partner information (including individual and
        business public reviews and ratings) to other websites, for example, so
        it can be included in search results or advertising (including online
        and offline advertising and promotional media, including, but not
        limited to Google, Google AdWords, Facebook Ads, Twitter, email
        distribution lists, and third-party business partners and affiliates).
      </p>

      <p>
        <strong>
          If Mido processes your information as a Client, Partner staff, or
          otherwise:
        </strong>
      </p>
      <p>We may also share your information with:</p>
      <ul>
        <li>
          Any member of our company group, which means our ultimate holding
          company and its subsidiaries, and/or our subsidiaries who may support
          our processing of personal information under this Notice.
        </li>
        <li>
          Our service providers, organizations who process your personal data on
          our behalf and in accordance with our instructions and the data
          protection law.
        </li>
        <li>Our auditors, legal advisers and other professional advisers.</li>
        <li>
          If we sell or buy any business or assets, in which case we will
          disclose your personal data to the prospective seller or buyer.
        </li>
        <li>
          Any person to whom disclosure is necessary to enable us to protect our
          rights, property, or safety, our clients, or other third parties, and
          to enforce our rights under this Notice or under any agreement (for
          example, our Terms of Use and Terms of Service) with you.
        </li>
        <li>
          If required to do so by court order or if we are under a duty to
          disclose your information in order to comply with (and/or where we
          believe we are under a duty to comply with) any legal obligation. This
          includes exchanging information with law enforcement agencies,
          regulators, or other similar government bodies.
        </li>
      </ul>

      <h2>Where do we store your information?</h2>
      <p>
        Mido operates globally, including in the United States, Canada, United
        Kingdom, Australia, the United Arab Emirates, Netherlands and other
        countries in the European Economic Area ("EEA"). We generally don't
        share personal data between group companies.
      </p>
      <p>We may transfer your personal information outside Ethiopia:</p>
      <ul>
        <li>In order to store it.</li>
        <li>In order to enable us to provide our Services to you.</li>
        <li>
          In order to facilitate the operation of our businesses, where it is in
          our legitimate interests and we have concluded these are not
          overridden by your rights.
        </li>
        <li>Where we are legally required to do so.</li>
      </ul>
      <p>
        We will put legal protections in place to safeguard personal data
        transfers in compliance with data protection laws.
      </p>

      <h2>How do we protect your information?</h2>
      <p>
        All information you provide to us is securely stored. Our website uses
        secure end-to-end encryption to protect your information. All
        connections into our platform are secured using industry standard
        security and encryption.
      </p>
      <p>
        All data we capture is stored in secured databases and data storage
        systems with strict access limitations. All data access requests are
        logged and monitored in accordance with any threat detection policies.
      </p>
      <p>
        Unfortunately, the transmission of information via the internet is not
        completely secure. We do our best to protect your personal information,
        but we cannot guarantee the security of your data transmitted to us –
        even if there is a special notice that a particular transmission (for
        example, credit card information) is encrypted. Any transmission is at
        your own risk. Once we have received your information, we use strict
        procedures and security features to try to prevent unauthorized access.
      </p>

      <h2>Payment processing</h2>
      <p>
        Payments made on our Services are made through our payment gateway
        provider, Adyen N.V., ("Adyen") (or, if you are in the UAE, Checkout
        MENA FFZ LLC ("Checkout")). You will be providing credit or debit card
        information directly to Adyen (or Checkout), which operates a secure
        server to process payment details, encrypting your credit/debit card
        information and authorizing payment. Mido can view pseudonymized
        cardholder and card details. In addition, Mido stores the following card
        information:
      </p>
      <ul>
        <li>Card brand</li>
        <li>Card holder name</li>
        <li>Card's last four digits</li>
        <li>Card expiry month and expiry year</li>
      </ul>
      <p>
        Information which you supply to Adyen (or Checkout) is not within our
        control and is subject to Adyen's own privacy policy and terms (or
        Checkout.com own privacy policy).
      </p>

      <h2>External sites</h2>
      <p>
        Mido may, from time to time, contain links to external sites. If you
        follow a link to any of these websites, please note that these websites
        have their own privacy policies. Mido does not accept any responsibility
        or liability for these policies or any content on external sites and
        does not necessarily endorse the views expressed within them. Mido has
        no control over the availability of any of these websites. Please check
        each site's policies before you submit any personal information to these
        websites.
      </p>

      <h2>How long is your information kept for?</h2>
      <p>We will generally process personal information:</p>
      <ul>
        <li>
          For as long as it is necessary for the purpose(s) for which it was
          originally collected (for example, holding it on behalf of a Partner,
          or for as long as you have an account with us), and
        </li>
        <li>
          For up to six years after that to identify any issues and resolve any
          legal proceedings.
        </li>
      </ul>
      <p>
        We may retain your personal data for a longer period in the event of a
        complaint, if we reasonably believe there is a prospect of legal
        proceedings, or we are aware of pending or ongoing legal proceedings. In
        some circumstances, we may keep data longer if applicable law says we
        have to.
      </p>
      <p>
        If you have opted to receive insights, newsletters, or other promotional
        material, but later decide to opt out from receiving these updates, or
        object to any other processing of your personal information, we may keep
        a record of your opt-out or objection so we can respect your
        preferences.
      </p>
      <p>
        We may keep aggregated, anonymized or pseudonymized data – for example,
        for reporting and analytics – for longer periods.
      </p>

      <h2>Aggregated data</h2>
      <p>
        In some circumstances we will anonymize your personal data (so that it
        can no longer be associated with you and you cannot be re-identified).
        This anonymized data (for example, aggregated statistics) is no longer
        personal data, and we may keep and use this anonymized information
        indefinitely without further notice to you.
      </p>
      <p>
        We use this to help us provide, develop and improve our services,
        including to:
      </p>
      <ul>
        <li>Better understand how people use our Services</li>
        <li>
          Provide our Partners and Clients with information about our Services,
          including user statistics (e.g. total numbers, broad demographics
          statistics)
        </li>
        <li>Develop useful insights and improvements to the Services</li>
      </ul>

      <h2>What rights do you have with your personal information?</h2>
      <p>In certain circumstances, you have the following rights:</p>
      <ul>
        <li>
          To be provided with a copy of your personal information held by us
        </li>
        <li>
          To request the correction or deletion of your personal information
          held by us
        </li>
        <li>
          To request that we restrict the processing of your personal
          information (while we verify or investigate your concerns with this
          information, for example)
        </li>
        <li>
          To object to the further processing of your personal information,
          including the right to object to marketing
        </li>
        <li>
          To request that your provided personal data be moved to a third party
        </li>
        <li>To withdraw consent</li>
      </ul>
      <p>
        If you wish to exercise any of these rights in relation to the personal
        information we hold about you, you can contact us at
        support@midobooking.com. If you have any concerns, you have the right to
        lodge a complaint with a data protection supervisory authority.
      </p>
      <p>
        This privacy policy outlines how we collect and use your personal
        information. By using our services, you consent to the collection and
        use of information in accordance with this policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
