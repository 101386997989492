import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import axios from "axios";
// Initialize Firebase app
const firebaseConfig = {
  apiKey: "AIzaSyDRwaTfrJ7FF1Sgbmge7PA6pqJboEEQ5kQ",
  authDomain: "mido-6da6b.firebaseapp.com",
  projectId: "mido-6da6b",
  storageBucket: "mido-6da6b.appspot.com",
  messagingSenderId: "444857689757",
  appId: "1:444857689757:web:bf47151f9b2904e0085674",
  measurementId: "G-2JK2L348ZC",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function HomePage() {
  const [formData, setFormData] = useState({
    businessName: "",
    phoneNumber: "",
    address: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true); // Set isSubmitting to true when form is being submitted

      const docRef = await addDoc(collection(db, "waitinglist"), formData);
      console.log("Document written with ID: ", docRef.id);
      sendSMS(formData.businessName, formData.phoneNumber);
      setFormData({
        businessName: "",
        phoneNumber: "",
        address: "",
      });
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setIsSubmitting(false); // Set isSubmitting back to false after form submission
    }
  };

  const sendSMS = async (businessName, phoneNumber) => {
    try {
      await axios.post("https://server.bafta.co/waitingListConfirmation", {
        name: businessName,
        phoneNumber: phoneNumber,
      });
      console.log("SMS sent successfully");
      alert("Thank you for registering on the waiting list!"); // Show alert message
      window.close(); // Close the window/tab
    } catch (error) {
      console.error("Error sending SMS:", error);
    }
  };

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      background: "linear-gradient(to bottom, #6E69B0, #FFFFFF)",
    },
    landingPage: {
      maxWidth: "400px",
      padding: "20px",
      border: "1px solid #ccc",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      background: "#f9f9f9",
    },
    title: {
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "20px",
      textAlign: "center",
      color: "#333",
    },
    description: {
      fontSize: "1.2rem",
      marginBottom: "20px",
      textAlign: "center",
      color: "#555",
    },
    formLabel: {
      display: "block",
      marginBottom: "8px",
      color: "#333",
    },
    formInput: {
      width: "100%",
      padding: "10px",
      marginBottom: "16px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      boxSizing: "border-box",
      fontSize: "1rem",
    },
    submitButton: {
      backgroundColor: "#4CAF50",
      color: "#fff",
      padding: "12px 20px",
      borderRadius: "4px",
      cursor: "pointer",
      width: "100%",
      fontSize: "1.1rem",
      border: "none",
      transition: "background-color 0.3s",
    },
    submitButtonHover: {
      backgroundColor: "#45a049",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.landingPage}>
        <h1 style={styles.title}>Welcome to Mido Booking</h1>
        <p style={styles.description}>
          Join the waiting list for early access!
        </p>

        <form onSubmit={handleSubmit}>
          <label style={styles.formLabel}>
            Business Name:
            <input
              type="text"
              name="businessName"
              value={formData.businessName}
              onChange={handleChange}
              style={styles.formInput}
              required
            />
          </label>

          <label style={styles.formLabel}>
            Phone Number:
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              style={styles.formInput}
              pattern="[0-9]*"
              placeholder="09xxxxxxxxx"
              required
            />
          </label>

          <label style={styles.formLabel}>
            Address:
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              style={styles.formInput}
              required
            />
          </label>

          <button
            type="submit"
            style={styles.submitButton}
            onMouseOver={(e) =>
              (e.target.style.backgroundColor =
                styles.submitButtonHover.backgroundColor)
            }
            onMouseOut={(e) =>
              (e.target.style.backgroundColor =
                styles.submitButton.backgroundColor)
            }
            disabled={isSubmitting} // Disable button when form is submitting
          >
            {isSubmitting ? "Loading..." : "Join Waiting List"}{" "}
            {/* Change button text based on form submission status */}
          </button>
        </form>
      </div>
    </div>
  );
}

export default HomePage;
